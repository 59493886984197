<template>
  <div class="d-flex flex-column">
    <LoadingMessage v-if="isLoading"></LoadingMessage>

    <div v-if="!isLoading && program">
      <div class="sticky bg-white border-bottom d-flex align-items-center p-3">
        <ProgramNameField class="flex-grow-1 me-3"></ProgramNameField>
        <CalendarViewModeConfig></CalendarViewModeConfig>
      </div>
      <ProgramCalendar :class="{ 'is-busy': programIsBusy }" class="flex-grow-1"></ProgramCalendar>
    </div>

    <portal to="modal">
      <transition name="modal">
        <SessionEditorModal
          @close="stopEditingSession"
          v-if="showSessionEditor"
          v-model="showSessionEditor"
          :programId="programId"
          :sessionId="programSessionIdInEditing"
          :athleteId="null"
        ></SessionEditorModal>
      </transition>
    </portal>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Programs',
  components: {
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    CalendarViewModeConfig: () => import('@/components/config/CalendarViewModeConfig'),
    ProgramNameField: () => import('@/components/field/ProgramNameField'),
    ProgramCalendar: () => import('@/components/program/ProgramCalendar'),
    SessionEditorModal: () => import('@/components/modal/SessionEditorModal'),
  },
  computed: {
    ...mapState('auth', ['authUser']),
    ...mapState('program', ['program', 'programIsBusy', 'programSessionIdInEditing', 'programSessionIdsInSelection', 'programSessionIdsInClipboard']),
    programId() {
      return this.$route.params.programId;
    },
    showSessionEditor() {
      return this.programSessionIdInEditing !== null;
    },
  },
  methods: {
    async fetchProgram() {
      this.isLoading = true;
      try {
        const { programId } = this;
        const { userId } = this.authUser;
        const payload = {
          programId,
          authorId: userId,
        };
        await this.$store.dispatch('program/fetchProgram', payload);
        await this.$store.dispatch('program/fetchSessionsForProgram', payload);
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isLoading = false;
      }
    },
    stopEditingSession() {
      this.$store.dispatch('program/setSessionInEditing', null);
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  mounted() {
    this.fetchProgram();
  },
  destroyed() {
    this.$store.dispatch('program/reset');
  },
};
</script>
